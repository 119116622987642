import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SocialButton(props) {
  let icon = props.socialIcon;

  return (
    <a
      href={props.socialLink}
      aria-label={props.socialTitle}
      title={props.socialTitle}
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon icon={['fab', icon]} />{" "}
      <span className="username">{props.socialUsername}</span>
    </a>
  );
}

export default SocialButton;