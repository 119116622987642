import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from "@fortawesome/free-brands-svg-icons";

import SocialButton from './components/social/SocialButton';

library.add(fab);

function App() {
  return (
    <div className="container">
      <main>
        <div className="name">
          <div className="title">Andy Dakin</div>
          <div className="subtitle">Front-End Developer & UI/UX Designer</div>
        </div>
      </main>
      <nav>
        <div className="social">
          <SocialButton socialLink="https://twitter.com/andrewdakin" socialTitle="Twitter Link - @andrewdakin" socialIcon="twitter" socialUsername="@andrewdakin" />
          <SocialButton socialLink="https://dribbble.com/andydakin" socialTitle="Dribbble Link - andydakin" socialIcon="dribbble" socialUsername="andydakin" />
          <SocialButton socialLink="https://www.behance.net/dakin" socialTitle="Behance Link - dakin" socialIcon="behance" socialUsername="dakin" />
          <SocialButton socialLink="https://github.com/dakin" socialTitle="Github Link - dakin" socialIcon="github" socialUsername="dakin" />
          <SocialButton socialLink="https://www.linkedin.com/in/andrewdakin" socialTitle="LinkedIn Link - andrewdakin" socialIcon="linkedin-in" socialUsername="andrewdakin" />
        </div>
      </nav>        
    </div>
  );
}

export default App;
